import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom'

function RequireAuth({ children, redirectTo }) {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login')
        }
    }, [isLoggedIn, navigate])
    return isLoggedIn ? children : <Navigate to={redirectTo} />;
}

export default RequireAuth