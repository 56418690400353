import {
    LOGIN_I,
    LOGIN_S,
    LOGIN_F,
    LOGOUT,
    AUTHENTICATE_USER,
    AUTHENTICATE_FAILED,
    GET_VENDOR_EVENT_DETAIL_S,
    GET_VENDOR_EVENT_DETAIL_F,
} from '../constants/types';

const initialState = {
    user: undefined,
    intermediate: undefined,
    isLoggedIn: false,

    // vendor
    vendorEventDetail: {}
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_I:
            return { ...state, intermediate: action.payload };
        case LOGIN_S:
            localStorage.setItem('authToken', JSON.stringify(action.payload.token));
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return { ...state, user: action.payload.data, isLoggedIn: true, intermediate: undefined };
        case AUTHENTICATE_USER:
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
        case LOGIN_F:
        case AUTHENTICATE_FAILED:
            localStorage.removeItem("authToken");
            localStorage.removeItem("user");
            return {
                ...state,
                isLoggedIn: false,
                user: {}
            }
        case LOGOUT:
            localStorage.removeItem("localCart");
            localStorage.removeItem("authToken");
            localStorage.removeItem("user");
            return {
                ...state,
                isLoggedIn: false,
                user: {}
            }
        case GET_VENDOR_EVENT_DETAIL_S:
            return {
                ...state,
                vendorEventDetail: action.payload.data
            };
        case GET_VENDOR_EVENT_DETAIL_F:
            return {
                ...state,
                vendorEventDetail: {}
            };

        default:
            return state;
    }
}

export default authReducer;