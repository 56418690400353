import { ORDER_DETAIL_FAIL, ORDER_DETAIL_SUCCESS, ORDER_LIST_FAIL, ORDER_LIST_SUCCESS, ORDER_PLACE_FAIL, ORDER_PLACE_SUCCESS } from '../constants/types';

const initialState = {
    orderResponse: undefined,
    orderList: undefined,
    orderDetail: undefined
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_PLACE_SUCCESS:
            return { ...state, orderResponse: action.payload.data };
        case ORDER_PLACE_FAIL:
            return { ...state, orderResponse: undefined };
        case ORDER_LIST_SUCCESS:
            return { ...state, orderList: action.payload.data };
        case ORDER_LIST_FAIL:
            return { ...state, orderList: undefined };
        case ORDER_DETAIL_SUCCESS:
            return { ...state, orderDetail: action.payload.data };
        case ORDER_DETAIL_FAIL:
            return { ...state, orderDetail: undefined };
        default:
            return state;
    }
}

export default orderReducer;