import { GET_FAQ_SUCCESS, GET_FAQ_FAIL } from "../constants/types";

const initialState = {
  faqData: undefined,
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ_SUCCESS:
      return { ...state, faqData: action.payload.data };
    case GET_FAQ_FAIL:
      return { ...state, faqData: undefined };
    default:
      return state;
  }
};

export default faqReducer;
