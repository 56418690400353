export const API = "API";
export const API_REQUEST = "API_REQUEST";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const LOGIN_I = "LOGIN_INTERMEDIATE";
export const LOGIN_S = "LOGIN_SUCCESS";
export const LOGIN_F = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const VENDOR_REGISTER_SUCCESS = "VENDOR_REGISTER_SUCCESS";
export const VENDOR_REGISTER_FAIL = "VENDOR_REGISTER_FAIL";

export const GET_VENDOR_EVENT_DETAIL_S = 'GET_VENDOR_EVENT_DETAIL_S'
export const GET_VENDOR_EVENT_DETAIL_F = 'GET_VENDOR_EVENT_DETAIL_F'

export const GET_HOMEPAGE_SUCCESS = "GET_HOMEPAGE_SUCCESS";
export const GET_HOMEPAGE_FAIL = "GET_HOMEPAGE_FAIL";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";

export const GET_ARTIST_SUCCESS = "GET_ARTIST_SUCCESS";
export const GET_ARTIST_FAIL = "GET_ARTIST_FAIL";

export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAIL = "GET_VENDOR_FAIL";

export const GET_CHANGEPWD_SUCCESS = "GET_CHANGEPWD_SUCCESS";
export const GET_CHANGEPWD_FAIL = "GET_CHANGEPWD_FAIL";

export const GET_FORGOTPWD_SUCCESS = "GET_FORGOTPWD_SUCCESS";
export const GET_FORGOTPWD_FAIL = "GET_FORGOTPWD_FAIL";

export const GET_RESETPWD_SUCCESS = "GET_RESETPWD_SUCCESS";
export const GET_RESETPWD_FAIL = "GET_RESETPWD_FAIL";

export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAIL = "GET_TICKET_FAIL";

export const GET_CARTDETAILS_SUCCESS = "GET_CARTDETAILS_SUCCESS";
export const GET_CARTDETAILS_FAIL = "GET_CARTDETAILS_FAIL";

export const ADDTOCART_SUCCESS = "ADDTOCART_SUCCESS";
export const ADDTOCART_FAIL = "ADDTOCART_FAIL";

export const UPDATECART_SUCCESS = "UPDATECART_SUCCESS";
export const UPDATECART_FAIL = "UPDATECART_FAIL";

export const GETPROFILE_DETAILS_SUCCESS = "GETPROFILE_DETAILS_SUCCESS";
export const GETPROFILE_DETAILS_FAIL = "GETPROFILE_DETAILS_FAIL";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS'
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL'

export const CLEARCART_SUCCESS = 'CLEARCART_SUCCESS'
export const CLEARCART_FAIL = 'CLEARCART_FAIL'
export const ORDER_PLACE_SUCCESS = "ORDER_PLACE_SUCCESS";
export const ORDER_PLACE_FAIL = "ORDER_PLACE_FAIL";

export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAIL = "GET_FAQ_FAIL";

export const CONTACTUS_SUBMIT_SUCCESS = "CONTACTUS_SUBMIT_SUCCESS";
export const CONTACTUS_SUBMIT_ERROR = "CONTACTUS_SUBMIT_ERROR";

export const GET_ARTIST_BY_ID_SUCCESS = "GET_ARTIST_BY_ID_SUCCESS";
export const GET_ARTIST_BY_ID_ERROR = "GET_ARTIST_BY_ID_ERROR";

export const GET_SPONSOR_DATA_SUCCESS = "GET_SPONSOR_DATA_SUCCESS";
export const GET_SPONSOR_DATA_ERROR = "GET_SPONSOR_DATA_ERROR";

export const GET_SPONSOR_SUCCESS = "GET_SPONSOR_DATA_SUCCESS";
export const GET_SPONSOR_ERROR = "GET_SPONSOR_DATA_ERROR";

export const UPDATE_LOCALCART_SUCCESS = "UPDATE_LOCALCART_SUCCESS";
export const UPDATE_LOCALCART_ERROR = "UPDATE_LOCALCART_ERROR";

export const LOCALCART_SUCCESS = "LOCALCART_SUCCESS";
export const LOCALCART_FAIL = "LOCALCART_FAIL";

export const GETTAX_SUCCESS = "GETTAX_SUCCESS";
export const GETTAX_FAIL = "GETTAX_FAIL";

export const DOWNLOAD_TICKET_SUCCESS = "DOWNLOAD_TICKET_SUCCESS";
export const DOWNLOAD_TICKET_FAIL = "DOWNLOAD_TICKET_FAIL";

export const RESETCODECHECK_SUCCESS = "RESETCODECHECK_SUCCESS";
export const RESETCODECHECK_FAIL = "RESETCODECHECK_FAIL";

export const SIPLIST_SUCCESS = "SIPLIST_SUCCESS";
export const SIPLIST_FAIL = "SIPLIST_FAIL";

export const SIPVIEW_SUCCESS = "SIPVIEW_SUCCESS";
export const SIPVIEW_FAIL = "SIPVIEW_FAIL";