import {
    GET_CARTDETAILS_SUCCESS,
    GET_CARTDETAILS_FAIL,
    UPDATE_LOCALCART_SUCCESS,
    UPDATE_LOCALCART_ERROR,
    GETTAX_FAIL,
    GETTAX_SUCCESS,
} from '../constants/types';

const initialState = {
    cartDetails: undefined,
    taxes: undefined
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CARTDETAILS_SUCCESS:
            return { ...state, cartDetails: action.payload.data };
        case GET_CARTDETAILS_FAIL:
            return { ...state, cartDetails: undefined };
        case UPDATE_LOCALCART_SUCCESS:
            localStorage.setItem('localCart', JSON.stringify(action.payload));
            return { ...state };
        case UPDATE_LOCALCART_ERROR:
            localStorage.removeItem("localCart")
            return { ...state };
        case GETTAX_SUCCESS:
            return { ...state, taxes: action.payload };
        case GETTAX_FAIL:
            return { ...state, taxes: undefined };
        default:
            return state;
    }
}

export default cartReducer;