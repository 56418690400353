import {
  GET_ARTIST_SUCCESS,
  GET_ARTIST_FAIL,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAIL,
  GET_ARTIST_BY_ID_SUCCESS,
  GET_ARTIST_BY_ID_ERROR,
} from "../constants/types";

const initialState = {
  artists: undefined,
  vendors: undefined,
  artistDetail: undefined,
};

const artistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTIST_SUCCESS:
      return { ...state, artists: action.payload.data };
    case GET_ARTIST_FAIL:
      return { ...state, artists: undefined };
    case GET_VENDOR_SUCCESS:
      return { ...state, vendors: action.payload.data };
    case GET_VENDOR_FAIL:
      return { ...state, vendors: undefined };
    case GET_ARTIST_BY_ID_SUCCESS:
      return { ...state, artistDetail: action.payload.data };
    case GET_ARTIST_BY_ID_ERROR:
      return { ...state, artistDetail: undefined };
    default:
      return state;
  }
};

export default artistReducer;
