export const { REACT_APP_API_BASE: API_BASE } = process.env;
//login
export const API_LOGIN = `${API_BASE}/loginUser`;

//Create Account
export const API_CREATE_ACC = `${API_BASE}/registerUser`;

//homepage

// Common
export const API_UPLOAD_IMAGE = `${API_BASE}/common/uploadImage`;
export const API_HOMEPAGE = `${API_BASE}/homepage/getHomePageData`;

//vendor Register
export const API_VENDOR_REG = `${API_BASE}/webvendor/registerVendor`;

//artists
export const API_ARTIST_LIST = `${API_BASE}/web-artist/artistList`;
export const API_ARTIST_DETAIL = `${API_BASE}/artistDetailsPage/artistDetailsPage/`;

//vendor list
export const API_VENDOR_LIST = `${API_BASE}/sip/listAllApprovedVendor`;

//Add vednor detail
export const API_ADD_VENDOR_DETAIL = `${API_BASE}/webvendor/createVendorEventDetail`;

// Get vendor event detail
export const API_GET_VENDOR_EVENT_DETAIL = (data) =>
  `${API_BASE}/webvendor/getEventVendorDetail/${data}`;

//change password
export const API_CHANGEPWD = `${API_BASE}/changePassword`;

//forgot password
export const API_FORGOTPWD = `${API_BASE}/userPassword/forgot`;

//reset password
export const API_RESETPWD = `${API_BASE}/userPassword/reset`;

//ticket list
export const API_TICKETLIST = (data) =>
  `${API_BASE}/web-ticket/ticketList/${data}`;

//cart details
export const API_CARTDETAILS = `${API_BASE}/cart/getByUser`;

//add to cart
export const API_ADDTOCART = `${API_BASE}/cart/create`;

//UPDATE cart
export const API_UPDATECART = `${API_BASE}/cart/updateQuantity`;

//get profile details
export const API_GETPROFILEDETAILS = (data) =>
  `${API_BASE}/getUserDetails/${data}`;

// EVENT
export const API_GET_EVENT_LIST = `${API_BASE}/event/get`;

// BOOTH
export const API_GET_BOOTH_LIST = `${API_BASE}/booth/listBooth`;

export const API_UPDATEPROFILE = (data) =>
  `${API_BASE}/updateUserProfile/${data}`;

//place order
export const API_PLACEORDER = `${API_BASE}/placeOrder/makePayment`;

//faq API
export const API_FAQ = `${API_BASE}/webFaq/getWebFaq`;

//order listing
export const API_ORDERLIST = `${API_BASE}/order/getHistory`;

//order details
export const API_ORDERDETAILS = `${API_BASE}/order/getDetailOrder`;

//clear cart
export const API_CLEARCART = `${API_BASE}/cart/clearCartByUser`;

//contact us
export const API_CONTACTUS = `${API_BASE}/contactUs/submitContactUs`;

//sponsor
export const API_SPONSOR = (data) => `${API_BASE}/ViewSporList/sponsorList/${data}`;
export const API_SPONSOR_GET = `${API_BASE}/ViewSporList/getSponserDetail/`;

// FOOD TRUCK LIST
export const API_GET_FOOD_TRUCK_LIST = `${API_BASE}/foodTruck/listFoodTruck`;

// VIP FOOD AREA LIST
export const API_GET_VIP_FOOD_AREA_LIST = `${API_BASE}/vipAreaFood/listVipAreaFood`;
//local cart
export const API_LOCALCART = `${API_BASE}/cart/createBulkCart`;

//local cart
export const API_GETTAXES = (data) => `${API_BASE}/cart/getTicketTaxes/${data}`;

//Appply coupon code
export const API_APPLY_COUPON_CODE = `${API_BASE}/coupon/applyCoupon`;

//Check qty before place order
export const API_CHECK_QTY_PLACE_ORDER = `${API_BASE}/common/checkQuantity `;

//download ticket
export const API_DOWNLOADTICKET = `${API_BASE}/placeOrder/generateTicketPdf`;

//register guest user
export const API_REGISTER_GUEST_USER = `${API_BASE}/registerGuestUser`;

//reset code check
export const API_RESETCODECHECK = (token) =>
  `${API_BASE}/userPassword/checkResetPasswordLink/${token}`;

//sip
export const API_SIPLIST = `${API_BASE}/sip/listAllSip`;
export const API_SIPVIEW = (data) => `${API_BASE}/sip/findOneSip/${data}`;

//register guest user
export const API_CHECK_ISCHALLENGE_VAL = `${API_BASE}/cart/isChallengeCart`;
