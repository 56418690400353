import { API_ADDTOCART, API_CARTDETAILS, API_CLEARCART, API_GETTAXES, API_LOCALCART, API_UPDATECART } from "../constants/api";
import { API, GET_CARTDETAILS_SUCCESS, GET_CARTDETAILS_FAIL, ADDTOCART_SUCCESS, ADDTOCART_FAIL, UPDATECART_SUCCESS, UPDATECART_FAIL, CLEARCART_SUCCESS, CLEARCART_FAIL, LOCALCART_SUCCESS, LOCALCART_FAIL, GETTAX_SUCCESS, GETTAX_FAIL } from "../constants/types";

//add to cart
export const addToCart = (data) => ({
    type: API,
    payload: {
        url: API_ADDTOCART,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: ADDTOCART_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: ADDTOCART_FAIL,
            payload: data
        })
    }
})

//get cart details
export const getCartDetails = (data) => ({
    type: API,
    payload: {
        url: API_CARTDETAILS,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: GET_CARTDETAILS_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_CARTDETAILS_FAIL,
            payload: data
        })
    }
})

//update quantity
export const updateCart = (data) => ({
    type: API,
    payload: {
        url: API_UPDATECART,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: UPDATECART_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: UPDATECART_FAIL,
            payload: data
        })
    }
})

//clear cart 
export const clearCart = (data) => ({
    type: API,
    payload: {
        url: API_CLEARCART,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: CLEARCART_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: CLEARCART_FAIL,
            payload: data
        })
    }
})

//add localCart
export const addLocalCart = (data) => ({
    type: API,
    payload: {
        url: API_LOCALCART,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: LOCALCART_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: LOCALCART_FAIL,
            payload: data
        })
    }
})

//get taxes
export const getTaxes = (data) => ({
    type: API,
    payload: {
        url: API_GETTAXES(data),
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GETTAX_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GETTAX_FAIL,
            payload: data
        })
    }
})