import { SIPLIST_FAIL, SIPLIST_SUCCESS, SIPVIEW_FAIL, SIPVIEW_SUCCESS } from "../constants/types";

const initialState = {
    sip: undefined,
    sipDetail: undefined,
};

const sipReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIPLIST_SUCCESS:
            return { ...state, sip: action.payload.data };
        case SIPLIST_FAIL:
            return { ...state, sip: undefined };
        case SIPVIEW_SUCCESS:
            return { ...state, sipDetail: action.payload.data };
        case SIPVIEW_FAIL:
            return { ...state, sipDetail: undefined };
        default:
            return state;
    }
};

export default sipReducer;