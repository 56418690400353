import { doLogout } from "../actions/auth";
import moment from 'moment'

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;
export const toEventImage = (pathname) =>
  `${process.env.REACT_APP_IMAGE_BASE}event/${pathname}`;
export const toArtistImage = (pathname) =>
  `${process.env.REACT_APP_IMAGE_BASE}artist/${pathname}`;
export const toVendorImage = (pathname) =>
  `${process.env.REACT_APP_IMAGE_BASE}vendor/${pathname}`;
export const toSponsorImage = (pathname) =>
  `${process.env.REACT_APP_IMAGE_BASE}sponsor/${pathname}`;
export const toDownloadTicket = (pathname) =>
  `${process.env.REACT_APP_FILE_BASE}/ticket-pdf/${pathname}`;
export const toSipImage = (pathname) =>
  `${process.env.REACT_APP_IMAGE_BASE}sip/${pathname}`;

export const setupAxios = (axios, store) => {
  axios.interceptors.request.use(
    (req) => {
      const token = JSON.parse(localStorage.getItem("authToken"));
      if (token) {
        req.headers["Authorization"] = `Bearer ${token}`;
      }

      return req;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(null, (err) => {
    if (err.response) {
      if (err.response.status === 403) {
        store.dispatch(doLogout());

        return Promise.reject(err);
      } else return Promise.reject(err);
    } else if (err.request) {
      return Promise.reject({
        response: {
          data: {
            message: "Something went wrong, Please try again later!!!",
          },
        },
      });
    }
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject("");
  });
}

export function getValueFromPercentage(value, per) {
  if (value && per) {
    return ((Number(value) * Number(per)) / 100)
  }
  else {
    return 0
  }
}

export const dateFun = (from, to) => {
  console.log('moment(from).diff(moment(to), day) > 1: ', moment(to).diff(moment(from), 'days') > 1);
  if (moment(to).diff(moment(from), 'days') > 1) {
    return `${moment(from).format('DD MMMM')} - ${moment(to).format('DD MMMM')}`
  }
  else {
    return `${moment(from).format('DD MMMM')}`
  }
}
export function toFixedTrunc(x = '00', n = 2) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`
}