import { combineReducers } from "redux";

import api from "./api";
import auth from "./auth";
import homepageReducer from "./homepage";
import artistReducer from "./artist";
import ticketReducer from "./ticket";
import cartReducer from "./cart";
import profileReducer from "./profile";
import orderReducer from "./order";
import sipReducer from "./sip";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import faqReducer from "./faq";
import contactReducer from "./contact";
import sponsorReducer from "./sponsor";

const rootReducer = combineReducers({
  api,
  auth,
  form: formReducer,
  toastr: toastrReducer,
  home: homepageReducer,
  artist: artistReducer,
  ticket: ticketReducer,
  cart: cartReducer,
  profile: profileReducer,
  order: orderReducer,
  faq: faqReducer,
  contact: contactReducer,
  sponsor: sponsorReducer,
  sip: sipReducer
});

export default rootReducer;
