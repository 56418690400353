import {
    GETPROFILE_DETAILS_SUCCESS,
    GETPROFILE_DETAILS_FAIL,
} from '../constants/types';

const initialState = {
    profileData: undefined,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETPROFILE_DETAILS_SUCCESS:
            return { ...state, profileData: action.payload.data };
        case GETPROFILE_DETAILS_FAIL:
            return { ...state, profileData: undefined };
        default:
            return state;
    }
}

export default profileReducer;