import {
  GET_SPONSOR_DATA_ERROR,
  GET_SPONSOR_DATA_SUCCESS,
  GET_SPONSOR_ERROR,
  GET_SPONSOR_SUCCESS,
} from "../constants/types";

const initialState = {
  sponsor: undefined,
  sponsorDetail: undefined,
};

const sponsorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPONSOR_DATA_SUCCESS:
      return { ...state, sponsor: action.payload.data };
    case GET_SPONSOR_DATA_ERROR:
      return { ...state, sponsor: undefined };
    case GET_SPONSOR_SUCCESS:
      return { ...state, sponsorDetail: action.payload.data };
    case GET_SPONSOR_ERROR:
      return { ...state, sponsorDetail: undefined };
    default:
      return state;
  }
};

export default sponsorReducer;
