import {
    GET_HOMEPAGE_SUCCESS,
    GET_HOMEPAGE_FAIL,
} from '../constants/types';

const initialState = {
    homePageData: undefined,
};

const homepageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOMEPAGE_SUCCESS:
            return { ...state, homePageData: action.payload.data };
        case GET_HOMEPAGE_FAIL:
            return { ...state, homePageData: undefined };
        default:
            return state;
    }
}

export default homepageReducer;