import {
    GET_TICKET_SUCCESS,
    GET_TICKET_FAIL,
} from '../constants/types';

const initialState = {
    ticketList: undefined,
};

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKET_SUCCESS:
            return { ...state, ticketList: action.payload.data };
        case GET_TICKET_FAIL:
            return { ...state, ticketList: undefined };
        default:
            return state;
    }
}

export default ticketReducer;