import {
    API_ADD_VENDOR_DETAIL,
    API_CREATE_ACC,
    API_FORGOTPWD,
    API_GET_BOOTH_LIST,
    API_GET_EVENT_LIST,
    API_GET_FOOD_TRUCK_LIST,
    API_GET_VENDOR_EVENT_DETAIL,
    API_GET_VIP_FOOD_AREA_LIST,
    API_HOMEPAGE,
    API_LOGIN,
    API_RESETCODECHECK,
    API_RESETPWD,
    API_UPLOAD_IMAGE,
    API_VENDOR_REG,
} from "../constants/api";
import {
    API,
    LOGIN_S,
    LOGIN_F,
    LOGOUT,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    GET_HOMEPAGE_FAIL,
    GET_HOMEPAGE_SUCCESS,
    AUTHENTICATE_USER,
    AUTHENTICATE_FAILED,
    VENDOR_REGISTER_FAIL,
    VENDOR_REGISTER_SUCCESS,
    GET_RESETPWD_FAIL,
    GET_RESETPWD_SUCCESS,
    GET_FORGOTPWD_FAIL,
    GET_FORGOTPWD_SUCCESS,
    GET_VENDOR_EVENT_DETAIL_F,
    GET_VENDOR_EVENT_DETAIL_S,
    RESETCODECHECK_FAIL,
    RESETCODECHECK_SUCCESS,
} from "../constants/types";

export const doLogin = (data) => ({
    type: API,
    payload: {
        url: API_LOGIN,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: LOGIN_S,
            payload: data
        }),
        error: (data) => ({
            type: LOGIN_F,
            payload: data
        })
    }
})

//register
export const register = (data) => ({
    type: API,
    payload: {
        url: API_CREATE_ACC,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: REGISTER_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: REGISTER_FAIL,
            payload: data
        })
    }
})

//Common image upload
export const uploadImageFun = (data) => ({
    type: API,
    payload: {
        url: API_UPLOAD_IMAGE,
        method: 'POST',
        data: data,
    }
})


//add vender detail
export const getEventList = (data) => ({
    type: API,
    payload: {
        url: API_GET_EVENT_LIST,
        method: 'GET',
        data: data,
    }
})


//get booth list
export const getBoothList = (data) => ({
    type: API,
    payload: {
        url: API_GET_BOOTH_LIST + `?event=${data?.event ? data?.event : ''}`,
        method: 'GET',
        data: data,
    }
})

//get FOOD TRUCK list
export const getFoodTruckList = (data) => ({
    type: API,
    payload: {
        url: API_GET_FOOD_TRUCK_LIST + `?event=${data?.event ? data?.event : ''}`,
        method: 'GET',
        data: data,
    }
})

//get VIP AREA FOOD list
export const getVipAreaFoodList = (data) => ({
    type: API,
    payload: {
        url: API_GET_VIP_FOOD_AREA_LIST + `?event=${data?.event ? data?.event : ''}`,
        method: 'GET',
        data: data,
    }
})

//register vendor
export const vendorRegister = (data) => ({
    type: API,
    payload: {
        url: API_VENDOR_REG,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: VENDOR_REGISTER_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: VENDOR_REGISTER_FAIL,
            payload: data
        })
    }
})

//add vender detail
export const addVendorDetail = (data) => ({
    type: API,
    payload: {
        url: API_ADD_VENDOR_DETAIL,
        method: 'POST',
        data: data,
    }
})

//GET VENDOR EVENT DETAIL
export const getVendorEventDetail = (data) => ({
    type: API,
    payload: {
        url: API_GET_VENDOR_EVENT_DETAIL(data),
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_VENDOR_EVENT_DETAIL_S,
            payload: data
        }),
        error: (data) => ({
            type: GET_VENDOR_EVENT_DETAIL_F,
            payload: data
        })
    }
})

//GET HOMEPAGE
export const homepageData = (data) => ({
    type: API,
    payload: {
        url: API_HOMEPAGE,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_HOMEPAGE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_HOMEPAGE_FAIL,
            payload: data
        })
    }
})

export const doLogout = () => ({
    type: LOGOUT
})



//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: AUTHENTICATE_FAILED })
    }
}

//forgot password
export const forgotPassword = (data) => ({
    type: API,
    payload: {
        url: API_FORGOTPWD,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: GET_FORGOTPWD_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_FORGOTPWD_FAIL,
            payload: data
        })
    }
})

//reset password
export const resetPassword = (data) => ({
    type: API,
    payload: {
        url: API_RESETPWD,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: GET_RESETPWD_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_RESETPWD_FAIL,
            payload: data
        })
    }
})

//check resetCode
export const resetCodeCheck = (data) => ({
    type: API,
    payload: {
        url: API_RESETCODECHECK(data),
        method: 'GET',
        data: data,
        success: (data) => ({
            type: RESETCODECHECK_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: RESETCODECHECK_FAIL,
            payload: data
        })
    }
})