import React, { Suspense, useEffect } from 'react';
import axios from 'axios';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import store from '../store';
import Routes from './routes';
import ScrollToTop from '../components/common/scroll';
import Loader from '../components/common/loader';
import { setupAxios } from '../utils';
import ReduxToastr from "react-redux-toastr";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { authenticate, homepageData } from '../actions/auth';
import { AUTHENTICATE_FAILED } from '../constants/types';
import { getCartDetails } from '../actions/cart';
const { PUBLIC_URL } = process.env;

const token = JSON.parse(localStorage.getItem("authToken"));
const loggedInUser = JSON.parse(localStorage.getItem("user"));

if (token && loggedInUser) {
    store.dispatch(authenticate(loggedInUser));
} else {
    store.dispatch({ type: AUTHENTICATE_FAILED });
}

setupAxios(axios, store);


const AppContainer = () => {
    useEffect(() => {
        store.dispatch(homepageData())
            .then((res) => {
                token && store.dispatch(getCartDetails({ userId: loggedInUser?._id, eventId: res.data.event._id }))
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <Provider store={store}>
            <Suspense fallback={<Loader isSuspense />}>
                <Loader>
                    <BrowserRouter basename={PUBLIC_URL}>
                        <ReduxToastr
                            position="bottom-right" />
                        <ScrollToTop>
                            <Routes />
                        </ScrollToTop>
                    </BrowserRouter>
                </Loader>
            </Suspense>
        </Provider>
    )
}

export default AppContainer;