import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "../components/auth";

const Layout = lazy(() => import("../components/layout/index"));

const Home = lazy(() => import("../pages/Home"));
const Login = lazy(() => import("../pages/Login"));
const Registration = lazy(() => import("../pages/Registration"));
const TicketView = lazy(() => import("../pages/TicketView"));
const Sponsor = lazy(() => import("../pages/Sponsor"));
const Vendor = lazy(() => import("../pages/Vendor"));
const VendorRegistration = lazy(() => import("../pages/VendorRegistration"));
// const ManageEvent = lazy(() => import('../pages/ManageEvent'))
const ManageVendor = lazy(() => import("../pages/ManageVendor"));
const ResetPasword = lazy(() => import("../pages/ResetPasword"));
const ResetEmail = lazy(() => import("../pages/ResetPasword/ResetEmail"));
const Checkout = lazy(() => import("../pages/Checkout"));
const Artist = lazy(() => import("../pages/Artist"));
const TicketTracker = lazy(() => import("../pages/TicketTracker"));
const ChangePasword = lazy(() => import("../pages/ChangePassword/ChangePassword"));
const MyAccount = lazy(() => import("../pages/MyAccount"));
const FAQ = lazy(() => import("../pages/FAQ"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const OrderHistory = lazy(() => import("../pages/OrderHistrory"));
const OrderDetails = lazy(() => import("../pages/OrderDetails"));
const VenderCheckout = lazy(() => import("../pages/VendorCheckout"));
const SponsorDetails = lazy(() => import("../pages/SponsorDetails"));
const ArtistDetails = lazy(() => import("../pages/ArtistDetails"));
const Daily = lazy(() => import("../pages/DailySchedule"));
const SiteMap = lazy(() => import("../pages/Sitemap"));
const SIP = lazy(() => import("../pages/SIP"));
const SIPDetails = lazy(() => import("../pages/SIPDetails"));
const Term = lazy(() => import("../pages/Terms"));



const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/change_password"
          element={
            <RequireAuth redirectTo="/login">
              <ChangePasword />
            </RequireAuth>
          }
        />
        <Route path="/registration" element={<Registration />} />
        <Route path="/ticket_view/:id" element={<TicketView />} />
        <Route path="/sponsor_info" element={<Sponsor />} />
        <Route path="/vendor_info" element={<Vendor />} />
        <Route path="/vendor_registration" element={<VendorRegistration />} />
        <Route path="/sponsor_details/:id" element={<SponsorDetails />} />
        <Route path="/artist_details/:id" element={<ArtistDetails />} />
        <Route path="/manage_event_vendor" element={<ManageVendor />} />
        <Route path="/vendor_checkout/:id" element={<VenderCheckout />} />
        <Route path="/reset_pasword/:resetCode" element={<ResetPasword />} />
        <Route path="/reset_email" element={<ResetEmail />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/artist_list" element={<Artist />} />
        <Route path="/ticket_tracker" element={<TicketTracker />} />
        <Route
          path="/my_account"
          element={
            <RequireAuth redirectTo="/login">
              <MyAccount />
            </RequireAuth>
          }
        />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/order_history" element={
          <RequireAuth redirectTo="/login">
            <OrderHistory />
          </RequireAuth>
        } />
        <Route path="/order_details/:orderId" element={
          <RequireAuth redirectTo="/login">
            <OrderDetails />
          </RequireAuth>
        } />
        <Route path="/site_map" element={<SiteMap />} />
        <Route path="/daily_schedule" element={<Daily />} />
        <Route path="/term" element={<Term />} />
        <Route path="/sip" element={<SIP />} />
        <Route path="/sip_details/:id" element={<SIPDetails />} />
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="*" element={<Navigate replace to="/" />} />
        {/* <Route path="/payment/:paymentType" element={<Payment />} /> */}
      </Route>
    </Routes>
  );
};
export default Routing;
