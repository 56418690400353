import React from 'react';
import './index.scss';

const Loading = ({ isSuspense, children, loading }) => {

    return (
        <>
            {(isSuspense || loading) && <div className="spinner-wrap" >
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>}

            {children}
        </>
    );
}

export default Loading;